.create-tag-button {
    width: 100%;
}

.popout-button-container {
    border-top: 1px solid #DCDCDC;
}

.popout-menu {
    position: absolute;
    background-color: #FFFFFF;
    width: 100%;
}

.popout-scroll-container {
    overflow-y: auto;
    max-height: 11rem;
    width: 100%;
}

.keywords-input {
    width: 25.5rem;
}