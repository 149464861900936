.link-with-pointer {
    cursor: pointer;
}

.expand-collapse-button {
    white-space: nowrap;
}

.custom-card-additional-text {
    max-width: 38.25rem;
}