@import '~@els/els-styleguide-core/dist/els-styleguide-core.css';
@import '~@els/els-react--table/dist/els-react--table.min.css';
@import '~@els/els-react--loader/dist/els-react--loader.min.css';
@import '~@els/els-react--flyout/dist/els-styleguide-flyout.css';
@import '~@els/els-styleguide-divider/css/els-styleguide-divider.css';
@import '~@els/els-styleguide-badge/css/els-styleguide-badge.css';
@import '~@els/els-styleguide-nav-group/css/els-styleguide-nav-group.css';
@import '~@els/els-styleguide-modal/css/els-styleguide-modal.css';
@import '~@els/els-styleguide-tooltip/css/els-styleguide-tooltip.css';
@import '~@els/els-styleguide-card/css/els-styleguide-card.css';
@import '~@els/els-styleguide-form-field/css/els-styleguide-form-field.css';
@import '~@els/els-styleguide-pill/css/els-styleguide-pill.css';
@import '~@els/els-styleguide-toast/css/els-styleguide-toast.css';
@import '~@els/els-styleguide-link/css/els-styleguide-link.css';

@import './utilities.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-container {
  height: 100vh;
  flex-direction: column;
}

.scroll-container {
  overflow-y: auto;
}

.skill-content-editor__title {
  margin-bottom: 1rem;
}

.alert-box {
  background-color: #E7F4FD;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}