.manage-tags-modal {
    z-index: 9998 !important;
}

.manage-tags-modal-window {
    max-width: 37.75rem;
}

.saving-tags-modal {
    min-height: 16rem;
    max-width: 37.75rem;
}

.tags-scroll-box {
    overflow-y: auto;
    max-height: 16.75rem;
}