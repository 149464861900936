.toast-container {
    position: relative;
}

.toast {
    position: absolute;
    overflow: hidden;
}

.toast-transition-enter {
    transform: translateX(100%);
    opacity: 0;
}

.toast-transition-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 250ms ease
}

.toast-transition-exit {
    transform: translateX(0);
    opacity: 1;
}

.toast-transition-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: all 250ms ease;
}