.u-app-z-index--1 {
    z-index: 1;
}

.u-app-z-index--2 {
    z-index: 2;
}

.u-app-z-index--3 {
    z-index: 3;
}

.u-app-z-index--modal {
    // Leyden modals and toasts both always have 9999. We want toasts to show over modals.
    z-index: 9998 !important;
}

.react-icon-fix {
    position: relative !important;
}