.media-list__filename-cell {
    overflow-wrap: break-word;
    max-width: 10rem;
}

.media-list__comment-cell {
    overflow-wrap: break-word;
    max-width: 20rem;
}

.media-list__description-cell {
    max-width: 10rem;
    overflow-wrap: break-word;
}

.media-list__provider-header {
    width: 6.5rem;
}