.create-tag-button {
    width: 100%;
}

.popout-button-container {
    border-top: 1px solid #DCDCDC;
}

.popout-menu {
    position: absolute;
    background-color: #FFFFFF;
    width: 100%;
}

.popout-scroll-container {
    overflow-y: auto;
    max-height: 11rem;
    width: 100%;
}

.categories-specialties-select {
    width: 7.5rem;
}

.categories-specialties-input {
    width: 18rem;
}

.manage-tool-tip-parent {
    position: relative;
}

.manage-tool-tip {
    width: 21rem;
    white-space: normal;
    position: absolute;
    left: 8rem;
}